import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHandsHelping,
  faThumbsUp,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import PageLayout from "../components/PageLayout";

import ConsignacionImg from "../components/Images/consignacion-img";

const ArriendosPage = () => (
  <PageLayout>
    <Container className="page-layout mt-5">
      <Row>
        <Col>
          <h3 className="text-center text-uppercase">Arrendamientos</h3>
          <hr className="title-line" />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" className="mt-4" lg={{ size: 4, offset: 2 }}>
          <ConsignacionImg />
        </Col>
        <Col md="6" lg="4">
          <ul className="welcome-list list-unstyled mt-4">
            <li className="mb-3 d-flex align-self-center">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" fixedWidth />
              </span>
              <span>Garantizamos el pago mensual de su renta.</span>
            </li>
            <li className="mb-3 d-flex">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" fixedWidth />
              </span>
              <span>
                Asesor&iacute;a para fijar c&aacute;nones de Arrendamiento.
              </span>
            </li>
            <li className="d-flex">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" fixedWidth />
              </span>
              <span>
                P&oacute;lizas de protecci&oacute;n frente a incumplimientos de
                pago.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h4 className="text-center mt-5">
            ¿C&oacute;mo publico mi inmueble?
          </h4>
          <hr className="title-line" />
        </Col>
        <Col lg={{ size: 6, offset: 3 }}>
          <ul className="welcome-list list-unstyled text-center mt-4">
            <li className="mb-3">
              <h6>
                <FontAwesomeIcon
                  icon={faComments}
                  className="w-100 text-center mb-3"
                  size="3x"
                  color="#BCA068"
                  fixedWidth
                />
                Cont&aacute;ctenos a trav&eacute;s de nuestros canales.
              </h6>
              <div>
                <a href={`mailto:${process.env.GATSBY_ARRIENDOS_EMAIL}`}>
                  {process.env.GATSBY_ARRIENDOS_EMAIL}
                </a>
                <a
                  href={`tel:${process.env.GATSBY_ARRENDAMIENTOS_PHONE_1}`}
                  className="d-block mt-3"
                >
                  {process.env.GATSBY_ARRENDAMIENTOS_PHONE_1_DISPLAY}
                </a>
                <a
                  href={`tel:${process.env.GATSBY_ARRENDAMIENTOS_PHONE_2}`}
                  className="d-block mt-3"
                >
                  {process.env.GATSBY_ARRENDAMIENTOS_PHONE_2_DISPLAY}
                </a>
              </div>
            </li>
            <li className="mb-3">
              <h6>
                <FontAwesomeIcon
                  icon={faHandsHelping}
                  className="w-100 text-center mb-3"
                  size="3x"
                  color="#BCA068"
                  fixedWidth
                />
                Uno de nuestros asesores lo visitar&aacute; para acompañarlo en
                el proceso.
              </h6>
            </li>
            <li className="mb-3">
              <h6>
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  className="w-100 text-center mb-3"
                  size="3x"
                  color="#BCA068"
                  fixedWidth
                />
                Su inmueble ser&aacute; publicado en nuestra p&aacute;gina web,
                metrocuadrado.com y fincaraiz.com
              </h6>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h4 className="text-center mt-5">Requisitos</h4>
          <hr className="title-line" />
        </Col>
        <Col lg={{ size: 8, offset: 2 }}>
          <ul className="welcome-list">
            <li>Fotocopia c&eacute;dula(s) de (los) propietario(s).</li>
            <li>
              Fotocopia certificado de tradici&oacute;n y libertad del inmueble
              reciente.
            </li>
            <li>
              Fotocopia &uacute;ltimos recibos de servicios p&uacute;blicos
              cancelados (agua, luz, tel&eacute;fono y gas).
            </li>
            <li>
              Fotocopia &uacute;ltimo recibo de administraci&oacute;n cancelado
              y paz y salvo.
            </li>
            <li>Fotocopia &uacute;ltimo impuesto predial.</li>
            <li>Fotocopia R.U.T si el inmueble es comercial.</li>
            <li>Bolet&iacute;n de nomenclatura.</li>
            <li>Llaves del inmueble.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </PageLayout>
);

export default ArriendosPage;
